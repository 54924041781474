import Cookies from 'js-cookie'
import { tryCatch, pipe } from 'rambda'

const log = console.info
let userData
let getQueue = []
let SITE_DATA
let rampart

// @FIXME: This should be a user facing error function, so for now we will
// just throw an error for raven to catch but we should have a generic way
// to throw user facing errors
const errorFunction = function (error) {
	throw new Error('User Error', error)
}

// Initialise the `User` module
function init(siteData) {
	/* Initialise Rampart via CDN */
	if (typeof Rampart !== 'undefined') {
		rampart = new Rampart({
			site: rnn.site.shortdomain,
			callbackUri: window.location.origin + '/auth0/callback/',
			logoutUri: window.location.origin + '/auth0/logout/',
		})
	} else {
		return log('Rampart is not available!')
	}

	// Make a reference to siteData
	SITE_DATA = siteData
	requestUserData()
	userDisplayName()
}

// Takes a `Fn` and when the user data is ready, returns `UserData`
const get = (callback) => userData ? callback(userData) : getQueue.push(callback)

const setupLogin = (loginButton) => {
	loginButton?.addEventListener('click', async () => {
		const host = window.location.origin
		const callbackUri = host + "/auth0/callback/?redirectUri=" + window.location.href

		// Check if a user is already logged In and get user's Profile
		// getLoginStatus returns a Promise
		rampart.getLoginStatus()
			.then(function (payload) {
				if (payload.status === 'loggedin') {
					userData = payload
					clearGetQueue()
					// The user is logged in, go straight to the callbackUri
					window.location.href = callbackUri
				} else {
					//User not logged in, go to login.
					rampart.login(window.location.href)
				}
			})
			.catch(() => {
				// User not logged in, go to login.
				rampart.login(window.location.href)
			})
	})
}

const setupLogout = (logoutButton) => {
	logoutButton?.addEventListener('click', async () => {
		console.info(window.location.href)
		rampart.logout(window.location.href)
	})
}

function clearGetQueue() {
	let callback

	while (getQueue.length > 0) {
		callback = getQueue.pop()
		callback(userData)
		callback = null
	}
}

// Safely parse the user data, if we throw an error return `{}`
const parseUserData = tryCatch(pipe(atob, decodeURIComponent, JSON.parse), {})

// Returns the current `UserData` from a cookie, or an empty object
export const cookieUserData = () => parseUserData(Cookies.get('user_data'))

async function requestUserData() {
	// Check if a user is already logged In and get user's Profile
	// getLoginStatus returns a Promise
	rampart.getLoginStatus()
		.then(function (payload) {
			if (payload.status === 'loggedin') {
				userData = payload
				clearGetQueue()
			}
		})
		.catch(function (err) {
			// No User is logged in, do nothing
		})
}

// Set the inner contents of all elements with the class `.user_display_name` to
// the user's account name
const userDisplayName = () => get(user =>
	[...document.querySelectorAll('.user_display_name')]
		.map($e => $e.innerHTML = user.name))

// isSubscriber :: () => Boolean
export const isSubscriber = () =>
	Cookies.get('subscribed') === 'true' || cookieUserData().subscribed

const checkLoginUser = (isTributes) => {
	setupLogin(document.getElementById('login'))
	setupLogout(document.getElementById('logout'))

	get(isTributes ? showMYTProfileMenu : showBSSProfileMenu)
}

const showBSSProfileMenu = (user) => {
	if (user.status === 'loggedin') {
		document.querySelectorAll('.authenticated').forEach((element) => element.classList.remove('authenticated'))
		document.querySelector('.unauthenticated').remove()
		document.querySelector('.authenticated-user-first-name').innerText = user.name
		user.picture && (document.querySelector('.authenticated-user-avatar img').src = decodeURIComponent(user.picture))
	} else {
		document.querySelector('.unauthenticated').classList.remove('unauthenticated')
		document.querySelectorAll('.authenticated').forEach((element) => element.remove())
	}
}

const showMYTProfileMenu = (user) => {
	if (user.status === 'loggedin') {
		document.querySelector('.btn-login-signup').classList.add('inactive')
		document.querySelector('.profile-greeting').classList.remove('inactive')
		document.querySelector('.profile-greeting .authenticated-profile-greeting-text').textContent = `Hi, ${user.name}`
	} else {
		document.querySelector('.btn-login-signup').classList.remove('inactive')
		document.querySelector('.profile-greeting').classList.add('inactive')
	}
}

// export function generateSubscribedString() {
// 	const user = cookieUserData()
// 	if (!user) { return 'anonymous' }
// 	return user.isStaff === true
// 		? 'staff'
// 		: user.subscribed === true
// 			? 'subscribed'
// 			: 'account'
// }

export default { init, get, checkLoginUser }